import { RootState } from "@/stores/rootReducer";
import { FC } from "react";
import { useSelector } from "react-redux";

const TheProgress: FC = (): JSX.Element => {
  const { hidden } = useSelector((state: RootState) => state.progress);

  if (hidden) return null;

  return <div className="TheProgress"></div>;
};

export default TheProgress;
